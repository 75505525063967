


import Axios from "axios";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import QuestionnaireMixin from "@/mixins/questionnaire.mixin";
import { mixins } from "vue-class-component";

import { BASE_API_URL } from "../../../../config";
import { PRODUCT_TYPE, REFINANCE_PURPOSE } from "@/constants";
// import AddressComponent from "@/views/Address.vue";
@Component({})
export default class LoanPurposeComponent extends mixins(QuestionnaireMixin) {
  @Prop()
  public history;

  public loanPurpose = null;
  public dotLoader = true;
  public productType = PRODUCT_TYPE;
  public pvError = null;
  public refinanceTypes = REFINANCE_PURPOSE;

  public setLoanPurpose() {
    this.loanPurpose.posLAP.loanPurpose = this.loanPurpose.loanInformation.purpose;
    if (
      this.loanPurpose.posLAP.loanPurpose &&
      this.loanPurpose.posLAP.loanPurpose.ediValue == "16"
    ) {
      this.loanPurpose.posLAP.refinanceCase.purposeOfRefinance = null;
      this.loanPurpose.posLAP.currentLoanBalance = 0;
      this.loanPurpose.posLAP.cashOutAmount = 0;
      this.loanPurpose.posLAP.veteranStatus = null;
    } 
    //else {
      //this.loanPurpose.loanInformation.propertyValue = this.loanPurpose.loanInformation.minDownPayment = 0;
    //}
    this.loanPurpose.posLAP.militaryStatus = this.loanPurpose.posLAP.appliedVALoan = false;

  }

  public async setStateCounty() {
    try {
      let zipCode = this.loanPurpose.loanInformation.zipCode;
      this.loanPurpose.posLAP.propertyAddress.address.zip = this.loanPurpose.loanInformation.zipCode;
      this.loanPurpose.loanInformation.subjectPropertyAddress.zip=this.loanPurpose.loanInformation.zipCode ;
      let response = await Axios.get(
        "https://maps.googleapis.com/maps/api/geocode/json?sensor=true&key=AIzaSyC4z_vM_4vNFs5_KH5wUdTjJrP8yLS9lr8&components=country:US|postal_code:" +
          zipCode,
          { headers: { public: true}}
      );
      if (response.status == 200 && response.data.status == "OK") {
        response.data.results[0]["address_components"].forEach(element => {
          if (element.types.includes("locality")) {
            this.loanPurpose.loanInformation.subjectPropertyAddress.city =
              element.long_name;
            this.loanPurpose.posLAP.propertyAddress.address.city =
              element.long_name;
          } else if (
            !this.loanPurpose.loanInformation.subjectPropertyAddress.city &&
            element.types.includes("administrative_area_level_3")
          ) {
            this.loanPurpose.loanInformation.subjectPropertyAddress.city =
              element.long_name;
            this.loanPurpose.posLAP.propertyAddress.address.city =
              element.long_name;
          }

          if (element.types.includes("administrative_area_level_1")) {
            this.loanPurpose.loanInformation.subjectPropertyAddress.state = {
              name: element.long_name,
              code: element.short_name
            };
            this.loanPurpose.posLAP.propertyAddress.address.state = {
              name: element.long_name,
              code: element.short_name
            };
          }
          if (element.types.includes("country")) {
            this.loanPurpose.loanInformation.subjectPropertyAddress.country =
              element.long_name;
            this.loanPurpose.posLAP.propertyAddress.address.country =
              element.long_name;
          }
        });
      } else if (response.data.status == "ZERO_RESULTS") {
        this.loanPurpose.loanInformation.subjectPropertyAddress.city = this.loanPurpose.posLAP.propertyAddress.address.city = null;
        this.loanPurpose.loanInformation.subjectPropertyAddress.state = this.loanPurpose.posLAP.propertyAddress.address.state = null;
        this.loanPurpose.loanInformation.subjectPropertyAddress.country = this.loanPurpose.posLAP.propertyAddress.address.country = null;
      }
    } catch (error) {
      console.log(error);
    }
  }
  public pvErrorMsg(){
    let pv:any = parseFloat(this.loanPurpose.loanInformation.propertyValue);
    let mdp:any = parseFloat(this.loanPurpose.loanInformation.minDownPayment);
    if (this.loanPurpose.loanInformation.minDownPaymentType == "$" && this.loanPurpose.loanInformation.propertyValue!=0) {
    if(pv<=mdp){
      if(pv==mdp){
      this.pvError=" cannot equal to down payment."}
      else{
        this.pvError=" cannot be less than down payment."
      }
        return
      }
      else{this.pvError=null;
      this.setLoanAmount();}
     
      }
       else{
        this.pvError=null;
        this.setLoanAmount(); }

  }
   
  public setLoanAmount() {
    let pv:any = parseFloat(this.loanPurpose.loanInformation.propertyValue);
    let mdp:any = parseFloat(this.loanPurpose.loanInformation.minDownPayment);
    if (this.loanPurpose.loanInformation.minDownPaymentType == "$") {
      if(pv<=mdp && pv!=0){
        return
      }
      this.loanPurpose.posLAP.loanAmount = this.loanPurpose.loanInformation.loanAmount =
        (pv - mdp).toFixed(2);
    } else {
      this.loanPurpose.posLAP.loanAmount = this.loanPurpose.loanInformation.loanAmount =
        (pv - (pv * mdp) / 100).toFixed(2);
    }
    let la = this.loanPurpose.loanAmount;
    let ltv = (la / pv) * 100;
    ltv = isNaN(ltv) || !isFinite(ltv) ? 0 : Math.round(ltv * 100) / 100;
    this.loanPurpose.loanInformation.LTV = ltv;
    this.loanPurpose.loanInformation.CLTV = ltv;
    this.loanPurpose.posLAP.propertyAddress.propertyValue=this.loanPurpose.loanInformation.propertyValue;
  }

  public async saveLoanPurpose(nextQuestion?: number, currentQuestion?: number, scope?: string, nextSection?: string) {
    try {
      let formValid = await this.$validator.validateAll(scope);
      if (!formValid)
        return;
        if (scope != 'LP1' && this.loanPurpose.loanInformation.minDownPaymentType=="$") {
          let pv:any = parseFloat(this.loanPurpose.loanInformation.propertyValue);
          let mdp:any = parseFloat(this.loanPurpose.loanInformation.minDownPayment);
           if(pv<=mdp)
            return
        }
      this.$store.state.wemloLoader = true;
      let res = await Axios.post(
        BASE_API_URL + "pos/saveLoanPurposeDetail",
        {
          loanPurpose: this.loanPurpose,
          loanTxnId: this.$route.query.loanTxnId
        });
      this.$store.state.wemloLoader = false;
      this.$snotify.clear();
      this.$snotify.success("Information saved successfully", {
        timeout: 1000
      });
      this.onNext(nextQuestion, currentQuestion, scope, nextSection);
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
      this.$snotify.error(
        "We are unable to save your details. Please try contacting the administrator."
      );
    }
  }

  public async loanPurposeDetail() {
    try {
      this.dotLoader = true;
      let res = await Axios.get(BASE_API_URL + "pos/getLoanPurposeDetail", {
        params: {
          loanTxnId: this.$route.query.loanTxnId
        }
      });
      this.loanPurpose = res.data;
      this.dotLoader = false;
    } catch (error) {
      this.dotLoader = false;
    }
  }

  public showStreamline(type){
    if(type && type.ediValue == 'F1' && this.loanPurpose && this.loanPurpose.posLAP)
      return this.loanPurpose.posLAP.mortgageAppliedFor ? this.loanPurpose.posLAP.mortgageAppliedFor.ediValue == '01' ? false : true : true;
    else 
      return true;
  }

  public resetVALoan(){
    if(this.loanPurpose.posLAP.mortgageAppliedFor && this.loanPurpose.posLAP.mortgageAppliedFor.ediValue != '02')
      this.loanPurpose.posLAP.veteranStatus = null;

    if(this.loanPurpose && this.loanPurpose.posLAP 
    && this.loanPurpose.posLAP.mortgageAppliedFor 
    && this.loanPurpose.posLAP.mortgageAppliedFor.ediValue == '01' 
    && this.loanPurpose.posLAP.refinanceCase.purposeOfRefinance
    && this.loanPurpose.posLAP.refinanceCase.purposeOfRefinance.ediValue == 'F1')
      this.loanPurpose.posLAP.refinanceCase.purposeOfRefinance = null;

    this.loanPurpose.loanInformation.productType = this.loanPurpose.posLAP.mortgageAppliedFor;
  }

  public setMinDownPay() {
    try {
      let pv: any = this.loanPurpose.loanInformation.propertyValue;
      let mdp: any = this.loanPurpose.loanInformation.minDownPayment;
      let dpt: any = this.loanPurpose.loanInformation.minDownPaymentType;
      let la: any = this.loanPurpose.loanInformation.loanAmount;
      
      if (pv != 0) {
        if (pv > la) {
          if (dpt == "$") {
            mdp = this.loanPurpose.loanInformation.minDownPayment = pv - la;
          } else {
            mdp = this.loanPurpose.loanInformation.minDownPayment =
              ((pv - la) / pv) * 100;
          }
        }

      }
    } catch (error) {
      console.log(error);
    }
  }

  async mounted() {
    this.questionHistory = this.history;
   await this.loanPurposeDetail();
    this.setMinDownPay();
  }
}
